import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    AUTO_LOGIN_SUCCESS,
    AUTO_LOGIN_FAIL,
    SET_NOTIFICATIONS,
    UPDATE_USER_EXP,
} from "../actions/types";

const initialState = {
    token: localStorage.getItem("token") || sessionStorage.getItem("token"),
    isAuthenticated: null,
    loading: true,
    user: null,
};

function authReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case LOGIN_SUCCESS:
        case AUTO_LOGIN_SUCCESS:
            return {
                ...state,
                token: payload.token,
                isAuthenticated: true,
                loading: false,
                user: payload.user,
            };
        case LOGIN_FAIL:
        case LOGOUT:
        case AUTO_LOGIN_FAIL:
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                loading: false,
                user: null,
            };
        case SET_NOTIFICATIONS:
            return {
                ...state,
                user: state.user
                    ? {
                          ...state.user,
                          notifications: action.payload,
                      }
                    : null,
            };
        case UPDATE_USER_EXP:
            return {
                ...state,
                user: {
                    ...state.user,
                    faith_exp: action.payload,
                },
            };
        default:
            return state;
    }
}

export default authReducer;
