// import React, { useEffect } from "react";
// import styled from "styled-components";
// import MainImage from "../components/MainImage";
// import CategoryButtons from "../components/CategoryButtons";
// import { useTheme } from "../contexts/ThemeContext"; // ThemeContext 경로를 확인해주세요

// const HomeWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `;

// function Home() {
//   const { toggleTheme, updatePrimaryColor } = useTheme();

//   useEffect(() => {
//     const savedTheme = localStorage.getItem("theme");
//     if (savedTheme === null) {
//       localStorage.setItem("theme", "light");
//     }

//     const savedPrimaryColor = localStorage.getItem("primaryColor");
//     if (savedPrimaryColor === null) {
//       localStorage.setItem("primaryColor", "#62A24D");
//       updatePrimaryColor("#62A24D");
//     }
//   }, [toggleTheme, updatePrimaryColor]);

//   return (
//     <HomeWrapper>
//       <MainImage />
//       <CategoryButtons />
//     </HomeWrapper>
//   );
// }

// export default Home;

import React, { useEffect } from "react";
import styled from "styled-components";

import { useTheme } from "../contexts/ThemeContext";
import { Helmet } from "react-helmet-async";

const HomeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    min-height: 100vh;
    background-color: ${({ theme }) => theme.background};
`;

const DevelopmentBanner = styled.div`
    background-color: ${({ theme }) => theme.notificationBackground};
    color: ${({ theme }) => theme.notificationText};
    padding: 10px;
    text-align: center;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
`;

const Title = styled.h1`
    font-size: 2.5rem;
    color: ${({ theme }) => theme.text};
    margin-bottom: 20px;
`;

const Subtitle = styled.p`
    font-size: 1.2rem;
    color: ${({ theme }) => theme.text};
    margin-bottom: 40px;
    text-align: center;
`;

const DesignGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    width: 100%;
    max-width: 1200px;
`;

const DesignCard = styled.div`
    background-color: ${({ theme }) => theme.cardBackground};
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;

    &:hover {
        transform: translateY(-5px);
    }
`;

const CardTitle = styled.h3`
    font-size: 1.2rem;
    color: ${({ theme }) => theme.text};
    margin-bottom: 10px;
`;

const CardDescription = styled.p`
    font-size: 1rem;
    color: ${({ theme }) => theme.text};
`;

function Home() {
    const { toggleTheme, updatePrimaryColor } = useTheme();

    useEffect(() => {
        const savedTheme = localStorage.getItem("theme");
        if (savedTheme === null) {
            localStorage.setItem("theme", "light");
        }

        const savedPrimaryColor = localStorage.getItem("primaryColor");
        if (savedPrimaryColor === null) {
            localStorage.setItem("primaryColor", "#62A24D");
            updatePrimaryColor("#62A24D");
        }
    }, [toggleTheme, updatePrimaryColor]);

    return (
        <>
            <Helmet>
                <title>코람데오</title>
                <meta
                    name="description"
                    content="신새대를 위한 코람데오 기독교 소통의 장"
                />
                <meta
                    name="keywords"
                    content="기도, 감사, 성경, 소통, 복음, 나눔, 사람, 사랑"
                />
                <link rel="canonical" href="https://www.coramdeus.com/" />
            </Helmet>
            <DevelopmentBanner>
                🚧 현재 개발 중인 웹사이트입니다 🚧
            </DevelopmentBanner>
            <HomeWrapper>
                <Title>코람데오 디자인 시스템</Title>
                <Subtitle>
                    성경적 가치를 담은 현대적이고 직관적인 디자인 솔루션
                </Subtitle>
                <DesignGrid>
                    <DesignCard>
                        <CardTitle>색상 팔레트</CardTitle>
                        <CardDescription>
                            영성과 평화를 상징하는 조화로운 색상 조합
                        </CardDescription>
                    </DesignCard>
                    <DesignCard>
                        <CardTitle>타이포그래피</CardTitle>
                        <CardDescription>
                            가독성과 심미성을 고려한 폰트 시스템
                        </CardDescription>
                    </DesignCard>
                    <DesignCard>
                        <CardTitle>아이콘 세트</CardTitle>
                        <CardDescription>
                            기독교 상징을 현대적으로 재해석한 아이콘
                        </CardDescription>
                    </DesignCard>
                    <DesignCard>
                        <CardTitle>레이아웃 시스템</CardTitle>
                        <CardDescription>
                            반응형 그리드로 모든 디바이스 지원
                        </CardDescription>
                    </DesignCard>
                </DesignGrid>
            </HomeWrapper>
        </>
    );
}

export default Home;
