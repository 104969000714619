import React, { useState } from "react";
import styled from "styled-components";

const BirthdateStepContainer = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.text};
`;

const Description = styled.p`
  text-align: center;
  margin-bottom: 20px;
  font-size: 16px;
  color: ${({ theme }) => theme.text};
  opacity: 0.8;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DropdownContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Dropdown = styled.div`
  position: relative;
  width: 30%;
`;

const DropdownHeader = styled.div`
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.inputBackground};
  color: ${({ theme }) => theme.inputText};
  cursor: pointer;
  margin-bottom: 10px;
`;

const DropdownList = styled.ul`
  position: absolute;
  width: 100%;
  max-height: 150px; /* Show 5 items with 30px each */
  overflow-y: auto;
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.inputBackground};
  color: ${({ theme }) => theme.inputText};
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 10;

  /* Hide the scrollbar */
  &::-webkit-scrollbar {
    width: 0px;
  }
  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`;

const DropdownListItem = styled.li`
  position: relative;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.highlightColor};
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 20%;
    right: 20%;
    border-bottom: 2px solid ${({ theme }) => theme.borderColor};
  }
`;

const SubmitButton = styled.button`
  width: 85%;
  padding: 15px;
  background-color: ${(props) =>
    props.disabled ? props.theme.toggleBorder : props.theme.buttonBackground};
  color: ${({ theme }) => theme.buttonText};
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: background-color 0.3s;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);

  &:hover {
    background-color: ${(props) =>
      !props.disabled && props.theme.buttonHoverBackground};
  }
`;

const Message = styled.p`
  font-size: 14px;
  margin-top: 5px;
  color: ${(props) => (props.error ? "red" : "green")};
`;

const generateYearOptions = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = currentYear; i >= 1900; i--) {
    years.push(i);
  }
  return years;
};

const generateMonthOptions = () => {
  const months = [];
  for (let i = 1; i <= 12; i++) {
    months.push(i);
  }
  return months;
};

const generateDayOptions = (year, month) => {
  const days = [];
  const daysInMonth = new Date(year, month, 0).getDate();
  for (let i = 1; i <= daysInMonth; i++) {
    days.push(i);
  }
  return days;
};

const BirthdateStep = ({ updateSignupData, signupData }) => {
  const [year, setYear] = useState("2001");
  const [month, setMonth] = useState("4");
  const [day, setDay] = useState("7");
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const [isYearOpen, setIsYearOpen] = useState(false);
  const [isMonthOpen, setIsMonthOpen] = useState(false);
  const [isDayOpen, setIsDayOpen] = useState(false);

  const handleSelect = (setter, value) => {
    setter(value);
    setIsYearOpen(false);
    setIsMonthOpen(false);
    setIsDayOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!year || !month || !day) {
      setMessage("생년월일을 모두 선택해주세요. 🎂");
      setIsError(true);
      return;
    }

    const birthdate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    updateSignupData({ birthdate });
  };

  return (
    <BirthdateStepContainer>
      <Title>생년월일을 입력해주세요 🎂</Title>
      <Description>회원님의 생년월일을 선택해주세요.</Description>
      <Form onSubmit={handleSubmit}>
        <DropdownContainer>
          <Dropdown>
            <DropdownHeader onClick={() => setIsYearOpen(!isYearOpen)}>
              {year ? `${year}년` : "년도"}
            </DropdownHeader>
            {isYearOpen && (
              <DropdownList>
                {generateYearOptions().map((y) => (
                  <DropdownListItem
                    key={y}
                    onClick={() => handleSelect(setYear, y)}
                  >
                    {y}년
                  </DropdownListItem>
                ))}
              </DropdownList>
            )}
          </Dropdown>
          <Dropdown>
            <DropdownHeader onClick={() => setIsMonthOpen(!isMonthOpen)}>
              {month ? `${month}월` : "월"}
            </DropdownHeader>
            {isMonthOpen && (
              <DropdownList>
                {generateMonthOptions().map((m) => (
                  <DropdownListItem
                    key={m}
                    onClick={() => handleSelect(setMonth, m)}
                  >
                    {m}월
                  </DropdownListItem>
                ))}
              </DropdownList>
            )}
          </Dropdown>
          <Dropdown>
            <DropdownHeader onClick={() => setIsDayOpen(!isDayOpen)}>
              {day ? `${day}일` : "일"}
            </DropdownHeader>
            {isDayOpen && (
              <DropdownList>
                {generateDayOptions(year, month).map((d) => (
                  <DropdownListItem
                    key={d}
                    onClick={() => handleSelect(setDay, d)}
                  >
                    {d}일
                  </DropdownListItem>
                ))}
              </DropdownList>
            )}
          </Dropdown>
        </DropdownContainer>
        {message && <Message error={isError}>{message}</Message>}
        <SubmitButton type="submit" disabled={!year || !month || !day}>
          다음
        </SubmitButton>
      </Form>
    </BirthdateStepContainer>
  );
};

export default BirthdateStep;
