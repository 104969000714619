import React, { useState, useEffect } from "react";
import styled from "styled-components";

const PasswordStepContainer = styled.div`
  width: 95%;
  max-width: 400px;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.text};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  background-color: ${({ theme }) => theme.inputBackground};
  color: ${({ theme }) => theme.inputText};

  &:focus {
    border-color: ${({ theme }) => theme.inputFocusBorder};
    outline: none;
  }
`;

const ToggleButton = styled.span`
  position: absolute;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
`;

const SubmitButton = styled.button`
  width: 85%;
  padding: 15px;
  background-color: ${(props) =>
    props.disabled ? props.theme.toggleBorder : props.theme.buttonBackground};
  color: ${({ theme }) => theme.buttonText};
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: background-color 0.3s;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);

  &:hover {
    background-color: ${(props) =>
      !props.disabled && props.theme.buttonHoverBackground};
  }
`;

const Message = styled.p`
  font-size: 14px;
  margin-top: 5px;
  color: ${(props) => (props.error ? "red" : "green")};
`;

const StrengthMeter = styled.div`
  height: 5px;
  width: 100%;
  border-radius: 4px;
  background-color: #e0e0e0;
  margin-bottom: 10px;
`;

const StrengthLevel = styled.div`
  height: 100%;
  width: ${(props) => props.width};
  border-radius: 4px;
  background-color: ${(props) => props.color};
  transition: width 0.3s;
`;

const PasswordRules = styled.ul`
  font-size: 14px;
  margin-top: 10px;
  color: #666;
  line-height: 1.5;
`;

const PasswordStep = ({ updateSignupData, signupData }) => {
  const [password, setPassword] = useState(signupData.password || "");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [strength, setStrength] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    if (signupData.password) {
      setPassword(signupData.password);
    }
  }, [signupData.password]);

  const validatePassword = (password) => {
    // 비밀번호 유효성 검사: 최소 8자, 대소문자, 숫자, 특수문자 포함
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const calculateStrength = (password) => {
    let strengthLevel = 0;
    if (password.length >= 8) strengthLevel++;
    if (/[a-z]/.test(password) && /[A-Z]/.test(password)) strengthLevel++;
    if (/\d/.test(password)) strengthLevel++;
    if (/[@$!%*?&]/.test(password)) strengthLevel++;
    return strengthLevel;
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setStrength(calculateStrength(newPassword));
    setMessage("");
    setIsError(false);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setMessage("");
    setIsError(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validatePassword(password)) {
      setMessage(
        "비밀번호는 최소 8자 이상이며, 대소문자, 숫자, 특수문자를 포함해야 합니다. 🔐"
      );
      setIsError(true);
      return;
    }
    if (password !== confirmPassword) {
      setMessage("비밀번호가 일치하지 않습니다. ❌");
      setIsError(true);
      return;
    }

    updateSignupData({ password });
  };

  const getStrengthColor = (strengthLevel) => {
    switch (strengthLevel) {
      case 4:
        return "green";
      case 3:
        return "yellowgreen";
      case 2:
        return "orange";
      case 1:
        return "red";
      default:
        return "#e0e0e0";
    }
  };

  const getStrengthWidth = (strengthLevel) => {
    switch (strengthLevel) {
      case 4:
        return "100%";
      case 3:
        return "75%";
      case 2:
        return "50%";
      case 1:
        return "25%";
      default:
        return "0%";
    }
  };

  return (
    <PasswordStepContainer>
      <Title>비밀번호를 설정해주세요 🔐</Title>
      <Form onSubmit={handleSubmit}>
        {password && (
          <>
            <PasswordRules>
              <li>비밀번호는 최소 8자 이상이어야 합니다.</li>
              <li>대문자와 소문자를 포함해야 합니다.</li>
              <li>숫자를 포함해야 합니다.</li>
              <li>
                특수문자(@, $, !, %, *, ?, &) 중 하나 이상을 포함해야 합니다.
              </li>
            </PasswordRules>
            <StrengthMeter>
              <StrengthLevel
                width={getStrengthWidth(strength)}
                color={getStrengthColor(strength)}
              />
            </StrengthMeter>
          </>
        )}
        <InputContainer>
          <InputWrapper>
            <Input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={handlePasswordChange}
              placeholder="비밀번호"
              required
            />
            <ToggleButton
              onMouseDown={() => setShowPassword(true)}
              onMouseUp={() => setShowPassword(false)}
              onMouseLeave={() => setShowPassword(false)}
              onTouchStart={() => setShowPassword(true)}
              onTouchEnd={() => setShowPassword(false)}
            >
              👁️
            </ToggleButton>
          </InputWrapper>
          <InputWrapper>
            <Input
              type={showConfirmPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              placeholder="비밀번호 확인"
              required
            />
            <ToggleButton
              onMouseDown={() => setShowConfirmPassword(true)}
              onMouseUp={() => setShowConfirmPassword(false)}
              onMouseLeave={() => setShowConfirmPassword(false)}
              onTouchStart={() => setShowConfirmPassword(true)}
              onTouchEnd={() => setShowConfirmPassword(false)}
            >
              👁️
            </ToggleButton>
          </InputWrapper>
        </InputContainer>

        {message && <Message error={isError}>{message}</Message>}
        <SubmitButton
          type="submit"
          disabled={!password || !confirmPassword}
          active={password && confirmPassword}
        >
          다음
        </SubmitButton>
      </Form>
    </PasswordStepContainer>
  );
};

export default PasswordStep;
