export function calculateLevelAndProgress(exp) {
    let level = 1;
    let expForNextLevel = Math.pow((level * 50) / 49, 2.5);
  
    while (exp >= expForNextLevel) {
      exp -= expForNextLevel;
      level++;
      expForNextLevel = Math.pow((level * 50) / 49, 2.5);
    }
  
    const progress = (exp / expForNextLevel) * 100;
    return { level, progress, expForNextLevel: expForNextLevel.toFixed(2) };
  }
  