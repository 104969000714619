import axios from "axios";
import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    AUTO_LOGIN_SUCCESS,
    AUTO_LOGIN_FAIL,
    SET_NOTIFICATIONS,
    UPDATE_USER_EXP
} from "./types";

// 로그인 액션
export const login = (email, password, rememberMe) => async (dispatch) => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/auth/login`,
            {
                email,
                password,
            }
        );

        if (rememberMe) {
            localStorage.setItem("token", res.data.token);
        } else {
            sessionStorage.setItem("token", res.data.token);
        }

        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: LOGIN_FAIL,
        });
    }
};
// 자동 로그인 액션
export const autoLogin = () => async (dispatch) => {
    const token =
        localStorage.getItem("token") || sessionStorage.getItem("token");

    if (!token) {
        dispatch({ type: AUTO_LOGIN_FAIL });
        return;
    }

    try {
        const res = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/auth/me`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: AUTO_LOGIN_SUCCESS,
            payload: { token, user: res.data },
        });
    } catch (err) {
        localStorage.removeItem("token");
        sessionStorage.removeItem("token");
        dispatch({ type: AUTO_LOGIN_FAIL });
    }
};
// 로그아웃 액션
export const logout = () => (dispatch) => {
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
    dispatch({ type: LOGOUT });
};

export const setNotifications = (count) => ({
    type: SET_NOTIFICATIONS,
    payload: count,
});
export const updateUserExp = (exp) => ({
    type: UPDATE_USER_EXP,
    payload: exp
});