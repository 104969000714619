const defaultPrimaryColor = "#7AC674";

export const lightTheme = (primaryColor = defaultPrimaryColor) => ({
    body: "#FFF",
    text: "#363537",
    toggleBorder: "#c7c7c7",
    background: "#f1f1f1",
    headerBackground: "#ebebeb",
    headerText: "#363537",
    headerHoverText: primaryColor,
    formBackground: "#fff",
    inputBorder: "#ccc",
    inputBackground: "#fff",
    inputText: "#363537",
    inputFocusBorder: primaryColor,
    buttonBackground: primaryColor,
    buttonText: "#fff",
    buttonHoverBackground: primaryColor,
    linkColor: primaryColor,
    linkHoverColor: primaryColor,
    footerBackground: "#ebebeb",
    footerText: "#363537",
    notificationBackground: primaryColor,
    notificationText: "#fff",
    borderColor: primaryColor,
});

export const darkTheme = (primaryColor = defaultPrimaryColor) => ({
    body: "#1E1E1E",
    text: "#FAFAFA",
    toggleBorder: "#6B8096",
    background: "#121212",
    headerBackground: "#1F1F1F",
    headerText: primaryColor,
    headerHoverText: primaryColor,
    formBackground: "#2C2C2C",
    inputBorder: "#3E3E3E",
    inputBackground: "#3C3C3C",
    inputText: "#fff",
    inputFocusBorder: primaryColor,
    buttonBackground: primaryColor,
    buttonText: "#fff",
    buttonHoverBackground: primaryColor,
    linkColor: primaryColor,
    linkHoverColor: primaryColor,
    footerBackground: "#1F1F1F",
    footerText: primaryColor,
    notificationBackground: primaryColor,
    notificationText: "#1E1E1E",
    borderColor: primaryColor,
});

export const defaultTheme = {
    body: "#f0f0f0",
    text: "#333333",
    toggleBorder: "#888888",
    background: "#ffffff",
    buttonBackground: "#4CAF50",
    buttonHoverBackground: "#45a049",
    headerBackground: "#f8f9fa",
    headerText: "#363537",
    formBackground: "#ffffff",
    inputBorder: "#cccccc",
    inputBackground: "#ffffff",
    inputText: "#363537",
    linkColor: "#4CAF50",
    footerBackground: "#f8f9fa",
    footerText: "#363537",
    notificationBackground: "#4CAF50",
    notificationText: "#ffffff",
    borderColor: "#4CAF50",
};
