import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const CompletionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  color: ${({ theme }) => theme.text};
`;

const Title = styled.h2`
  margin-bottom: 20px;
`;

const Message = styled.p`
  margin-bottom: 20px;
  font-size: 18px;
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #218838;
  }
`;

const CompletionStep = ({ signupData }) => {
  const navigate = useNavigate();
  const [serverError, setServerError] = useState(null);
  const [isSignupComplete, setIsSignupComplete] = useState(false);
  const handleLoginClick = () => {
    navigate("/login");
  };

  const missingStep = () => {
    if (!signupData.agreements)
      return { path: "/signup/agreement", name: "약관 동의" };
    if (!signupData.themeSelected)
      return { path: "/signup/theme-selection", name: "테마 선택" };
    if (!signupData.primaryColor)
      return { path: "/signup/theme-selection", name: "테마 선택" };
    if (!signupData.name) return { path: "/signup/name", name: "이름 입력" };
    if (!signupData.email)
      return { path: "/signup/email", name: "이메일 입력" };
    if (!signupData.isVerified)
      return { path: "/signup/email", name: "이메일 인증" };
    if (!signupData.password)
      return { path: "/signup/password", name: "비밀번호 설정" };
    if (!signupData.birthdate)
      return { path: "/signup/birthdate", name: "생년월일 입력" };
    if (!signupData.nickname)
      return { path: "/signup/nick", name: "닉네임 설정" };
    return null;
  };

  const missing = missingStep();

  const completeSignup = async () => {
    if (missing === null && !isSignupComplete) {
      setIsSignupComplete(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/auth/complete-signup`,
          signupData
        );
        if (response.data.success) {
          console.log("회원가입이 완료되었습니다.");
        }
      } catch (error) {
        setServerError("서버에 문제가 발생했습니다. 나중에 다시 시도해주세요.");
      }
    }
  };

  completeSignup();

  return (
    <CompletionContainer>
      {missing === null ? (
        serverError ? (
          <>
            <Title>😔 죄송해요 😔</Title>
            <Message>{serverError}</Message>
            <Button onClick={handleLoginClick}>로그인 하러가기</Button>
          </>
        ) : (
          <>
            <Title>🎉 회원가입을 축하합니다! 🎉</Title>
            <Message>
              모든 정보를 정상적으로 입력하셨습니다. 이제 로그인하세요!
            </Message>
            <Button onClick={handleLoginClick}>로그인 하러가기</Button>
          </>
        )
      ) : (
        <>
          <Title>😔 죄송해요 😔</Title>
          <Message>{`잘못된 정보가 있습니다. ${missing.name} 페이지로 이동해주세요.`}</Message>
          <Button onClick={() => navigate(missing.path)}>
            {`${missing.name} 페이지로 이동하기`}
          </Button>
        </>
      )}
    </CompletionContainer>
  );
};

export default CompletionStep;
