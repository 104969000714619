import React, { useEffect } from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useLocation,
} from "react-router-dom";
import { Provider } from "react-redux";
import styled, { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./styles/GlobalStyles";
import { lightTheme, darkTheme } from "./styles/theme";
import {
    ThemeProvider as CustomThemeProvider,
    useTheme,
} from "./contexts/ThemeContext";
import store from "./redux/store";
import { autoLogin } from "./redux/actions/authActions";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Communication from "./pages/Communication";
import Gospel from "./pages/Gospel";
import Prayer from "./pages/Prayer";
import Gratitude from "./pages/Gratitude";
import Advanced from "./pages/Advanced";
import Profile from "./pages/userpages/Profile";
import Login from "./components/Login";
import Signup from "./pages/Signup";
import "./styles/main.scss";
import Notifications from "./components/Notifications";

const AppWrapper = styled.div`
    font-family: Arial, sans-serif;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;

const MainContent = styled.main`
    flex: 1;
    margin-top: 60px;
`;

function AppContent() {
    const location = useLocation();
    const isSignupPage = location.pathname.startsWith("/signup");
    const {
        isDarkMode,
        toggleTheme,
        primaryColor,
        setSystemTheme,
        isSystemTheme,
    } = useTheme();

    useEffect(() => {
        const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
        const handleChange = (e) => {
            if (isSystemTheme) {
                setSystemTheme(e.matches);
            }
        };

        // 초기 시스템 테마 설정
        if (isSystemTheme) {
            setSystemTheme(mediaQuery.matches);
        }

        mediaQuery.addListener(handleChange);
        return () => mediaQuery.removeListener(handleChange);
    }, [setSystemTheme, isSystemTheme]);

    useEffect(() => {
        store.dispatch(autoLogin());
    }, []);

    return (
        <ThemeProvider
            theme={
                isDarkMode ? darkTheme(primaryColor) : lightTheme(primaryColor)
            }
        >
            <GlobalStyles />
            <AppWrapper>
                {!isSignupPage && <Header toggleTheme={toggleTheme} />}
                <MainContent>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route
                            path="/communication"
                            element={<Communication />}
                        />
                        <Route path="/gospel" element={<Gospel />} />
                        <Route path="/prayer" element={<Prayer />} />
                        <Route path="/gratitude" element={<Gratitude />} />
                        <Route path="/advanced" element={<Advanced />} />
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/signup/*" element={<Signup />} />
                        <Route
                            path="/notifications"
                            element={<Notifications />}
                        />
                    </Routes>
                </MainContent>
                {!isSignupPage && <Footer />}
            </AppWrapper>
        </ThemeProvider>
    );
}

function App() {
    return (
        <Provider store={store}>
            <CustomThemeProvider>
                <Router>
                    <AppContent />
                </Router>
            </CustomThemeProvider>
        </Provider>
    );
}

export default App;
