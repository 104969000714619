import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

const ReferralStepContainer = styled.div`
  width: 95%;
  max-width: 400px;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.text};
  font-size: 18px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 4px;
  font-size: 16px;
  background-color: ${({ theme }) => theme.inputBackground};
  color: ${({ theme }) => theme.inputText};
  margin-bottom: 20px;

  &:focus {
    border-color: ${({ theme }) => theme.inputFocusBorder};
    outline: none;
  }
`;

const SubmitButton = styled.button`
  width: 85%;
  padding: 15px;
  background-color: ${(props) =>
    props.active ? props.theme.buttonBackground : props.theme.toggleBorder};
  color: ${({ theme }) => theme.buttonText};
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  cursor: ${(props) => (props.active ? "pointer" : "not-allowed")};
  transition: background-color 0.3s;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);

  &:hover {
    background-color: ${(props) =>
      props.active
        ? props.theme.buttonHoverBackground
        : props.theme.toggleBorder};
  }
`;

const ReferralStep = ({ updateSignupData, signupData }) => {
  const location = useLocation();
  const [referralCode, setReferralCode] = useState(
    signupData.referralCode || ""
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get("referralCode");
    if (code) {
      setReferralCode(code);
      updateSignupData({ referralCode: code });
    }
  }, [location, updateSignupData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateSignupData({ referralCode });
  };

  return (
    <ReferralStepContainer>
      <Title>🙌복음 전도번호를 입력해주세요🙌</Title>
      <Form onSubmit={handleSubmit}>
        <Input
          type="text"
          value={referralCode}
          onChange={(e) => setReferralCode(e.target.value)}
          placeholder="전도번호 (선택사항)"
        />
        <SubmitButton type="submit" active>
          다음
        </SubmitButton>
      </Form>
    </ReferralStepContainer>
  );
};

export default ReferralStep;
