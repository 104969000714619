import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { login, logout } from "../redux/actions/authActions";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const LoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: ${({ theme }) => theme.background};
    animation: ${fadeIn} 0.5s ease-in;
`;

const LoginForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 300px;
    background: ${({ theme }) => theme.formBackground};
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
    text-align: center;
    color: ${({ theme }) => theme.text};
    margin-bottom: 20px;
`;

const Input = styled.input`
    margin-bottom: 15px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid ${({ theme }) => theme.inputBorder};
    border-radius: 4px;
    transition: border-color 0.3s;
    background-color: ${({ theme }) => theme.inputBackground};
    color: ${({ theme }) => theme.inputText};

    &:focus {
        border-color: ${({ theme }) => theme.inputFocusBorder};
        outline: none;
    }
`;

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    color: ${({ theme }) => theme.primaryColor};
`;

const Checkbox = styled.input`
    margin-right: 10px;
`;

const Button = styled.button`
    padding: 10px;
    background-color: ${({ theme }) => theme.buttonBackground};
    color: ${({ theme }) => theme.buttonText};
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    margin-bottom: 10px;

    &:hover {
        background-color: ${({ theme }) => theme.buttonHoverBackground};
    }
`;

const SignupLink = styled(Link)`
    text-align: center;
    margin-top: 15px;
    color: ${({ theme }) => theme.linkColor};
    text-decoration: none;
    font-size: 14px;
    transition: color 0.3s;

    &:hover {
        color: ${({ theme }) => theme.linkHoverColor};
    }
`;

const WelcomeMessage = styled.div`
    color: ${({ theme }) => theme.text};
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
`;

const LogoutConfirmMessage = styled.div`
    color: ${({ theme }) => theme.text};
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
`;

function Login({ login, logout, isAuthenticated, user }) {
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });
    const [rememberMe, setRememberMe] = useState(false);
    const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
    const navigate = useNavigate();

    const { email, password } = formData;

    useEffect(() => {
        if (isAuthenticated) {
            navigate("/");
        }
    }, [isAuthenticated, navigate]);

    const onChange = (e) =>
        setFormData({ ...formData, [e.target.name]: e.target.value });

    const onCheckboxChange = (e) => {
        setRememberMe(e.target.checked);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        login(email, password, rememberMe);
    };

    const handleLogout = () => {
        setShowLogoutConfirm(true);
    };

    const confirmLogout = () => {
        logout();
        setShowLogoutConfirm(false);
    };

    const cancelLogout = () => {
        setShowLogoutConfirm(false);
    };

    if (isAuthenticated) {
        return (
            <LoginContainer>
                <WelcomeMessage>
                    환영합니다, {user.name}님!
                    <br />
                    이미 로그인되어 있습니다.
                </WelcomeMessage>
                {showLogoutConfirm ? (
                    <>
                        <LogoutConfirmMessage>
                            로그아웃 하시겠습니까?
                        </LogoutConfirmMessage>
                        <Button onClick={confirmLogout}>예, 로그아웃</Button>
                        <Button onClick={cancelLogout}>아니오, 취소</Button>
                    </>
                ) : (
                    <Button onClick={handleLogout}>로그아웃</Button>
                )}
            </LoginContainer>
        );
    }

    return (
        <LoginContainer>
            <LoginForm onSubmit={onSubmit}>
                <Title>로그인</Title>
                <Input
                    type="email"
                    placeholder="이메일"
                    name="email"
                    value={email}
                    onChange={onChange}
                    required
                />
                <Input
                    type="password"
                    placeholder="비밀번호"
                    name="password"
                    value={password}
                    onChange={onChange}
                    required
                />
                <CheckboxContainer>
                    <Checkbox
                        type="checkbox"
                        checked={rememberMe}
                        onChange={onCheckboxChange}
                    />
                    기기에서 자동 로그인
                </CheckboxContainer>
                <Button type="submit">로그인</Button>
                <SignupLink to="/signup">회원가입</SignupLink>
            </LoginForm>
        </LoginContainer>
    );
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
});

export default connect(mapStateToProps, { login, logout })(Login);
