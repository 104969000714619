import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { calculateLevelAndProgress } from "../utils";
import defaultProfileImage from "../assets/originalProfileImage.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { io } from "socket.io-client";
import { setNotifications, updateUserExp } from "../redux/actions/authActions";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTheme } from "../contexts/ThemeContext";
import axios from "axios";

const FooterWrapper = styled.footer`
    background-color: ${({ theme }) => theme.footerBackground};
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: transform 0.3s ease-in-out;
    transform: ${({ show }) => (show ? "translateY(0)" : "translateY(-100%)")};
    z-index: 1000;

    @media (max-width: 768px) {
        padding: 0.5rem;
    }
`;

const ProfileLink = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${({ theme }) => theme.footerText};

    img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 10px;
    }

    @media (max-width: 768px) {
        margin-bottom: 0.5rem;
    }
`;

const FaithInfo = styled.div`
    display: flex;
    gap: 20px;
    color: ${({ theme }) => theme.footerText};

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 5px;
        align-items: center;
        margin-bottom: 0.5rem;
    }
`;

const NotificationLink = styled(Link)`
    background-color: ${({ theme }) => theme.notificationBackground};
    color: ${({ theme }) => theme.text};
    padding: 5px 10px;
    border-radius: 15px;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 5px;

    @media (max-width: 768px) {
        margin-bottom: 0.5rem;
    }
`;

const LoginPrompt = styled(Link)`
    text-decoration: none;
    color: ${({ theme }) => theme.linkColor};
    font-weight: bold;
`;

const ProgressWrapper = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ProgressBarContainer = styled.div`
    width: 100%;
    height: 10px;
    background-color: ${({ theme }) => theme.inputBorder};
    overflow: hidden;
`;

const ProgressBar = styled.div`
    width: ${({ progress }) => `${progress}%`};
    height: 100%;
    background: ${({ theme }) => theme.borderColor};
    box-shadow: 10px 0 10px ${({ theme }) => theme.borderColor};
`;

function Footer() {
    const dispatch = useDispatch();
    const { isAuthenticated, user } = useSelector((state) => state.auth);
    const [show, setShow] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    const [socket, setSocket] = useState(null);
    const [unreadNotifications, setUnreadNotifications] = useState(0);
    const token =
        localStorage.getItem("token") || sessionStorage.getItem("token");
    const { isDarkMode, primaryColor } = useTheme();
    const fetchUnreadNotificationsCount = useCallback(async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/notifications/unread-count`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            setUnreadNotifications(response.data.count);
        } catch (error) {
            console.error("Error fetching unread notifications count:", error);
        }
    }, [token]);

    const handleNewNotification = useCallback(
        (notification) => {
            console.log(
                "New notification received in handleNewNotification:",
                notification
            );
            dispatch(setNotifications((prevCount) => prevCount + 1));
            if (notification.exp) {
                dispatch(updateUserExp(notification.exp));
            }
            toast.info(notification.message, {
                theme: isDarkMode ? "dark" : "light",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        },
        [dispatch, isDarkMode, fetchUnreadNotificationsCount]
    );

    const connectSocket = useCallback(() => {
        const newSocket = io(process.env.REACT_APP_SOCKET_URL, {
            transports: ["websocket"],
            reconnection: true,
            reconnectionAttempts: 5,
            reconnectionDelay: 1000,
            auth: { token },
        });

        newSocket.on("connect", () => {
            console.log("Socket connected successfully");
        });

        newSocket.on("authenticated", (response) => {
            console.log("Socket authenticated:", response.message);
        });
        newSocket.on("newNotification", (notification) => {
            console.log("New notification event received:", notification);
            handleNewNotification(notification);
        });
        newSocket.on("authentication_error", (error) => {
            console.error("Socket authentication failed:", error.message);
            toast.error(`인증 실패: ${error.message}`, {
                theme: isDarkMode ? "dark" : "light",
            });
            newSocket.disconnect();
        });

        newSocket.on("connect_error", (error) => {
            console.error("Socket connection error:", error.message);
            toast.error(`연결 오류: ${error.message}`, {
                theme: isDarkMode ? "dark" : "light",
            });
        });

        newSocket.on("disconnect", (reason) => {
            console.log("Socket disconnected, reason:", reason);
            toast.warn(`연결이 끊어졌습니다: ${reason}`, {
                theme: isDarkMode ? "dark" : "light",
            });
            if (reason === "io server disconnect") {
                newSocket.connect();
            }
        });

        newSocket.io.on("reconnect", (attempt) => {
            console.log("Socket reconnected, attempt:", attempt);
            toast.success(`재연결 성공 (시도: ${attempt})`, {
                theme: isDarkMode ? "dark" : "light",
            });
        });

        newSocket.io.on("reconnect_attempt", (attempt) => {
            console.log("Socket reconnection attempt:", attempt);
            toast.info(`재연결 시도 중... (시도: ${attempt})`, {
                theme: isDarkMode ? "dark" : "light",
            });
        });

        newSocket.io.on("reconnect_failed", () => {
            console.log("Socket reconnection failed");
            toast.error("재연결에 실패했습니다. 페이지를 새로고침해주세요.", {
                theme: isDarkMode ? "dark" : "light",
            });
        });

        setSocket(newSocket);

        return newSocket;
    }, [handleNewNotification, token, isDarkMode]);

    useEffect(() => {
        let currentSocket;
        if (isAuthenticated && user && token) {
            currentSocket = connectSocket();
            console.log("Socket connection initiated");
            fetchUnreadNotificationsCount();
        }

        const intervalId = setInterval(fetchUnreadNotificationsCount, 60000);

        return () => {
            if (currentSocket) {
                console.log("Cleaning up socket connection");
                currentSocket.off("newNotification");
                currentSocket.off("authenticated");
                currentSocket.off("authentication_error");
                currentSocket.off("connect_error");
                currentSocket.off("disconnect");
                currentSocket.io.off("reconnect");
                currentSocket.io.off("reconnect_attempt");
                currentSocket.io.off("reconnect_failed");
                currentSocket.close();
            }
            clearInterval(intervalId);
        };
    }, [
        isAuthenticated,
        user,
        connectSocket,
        token,
        fetchUnreadNotificationsCount,
    ]);

    useEffect(() => {
        const controlFooter = () => {
            if (typeof window !== "undefined") {
                if (window.scrollY > lastScrollY) {
                    setShow(false);
                } else {
                    setShow(true);
                }
                setLastScrollY(window.scrollY);
            }
        };

        if (typeof window !== "undefined") {
            window.addEventListener("scroll", controlFooter);
            return () => {
                window.removeEventListener("scroll", controlFooter);
            };
        }
    }, [lastScrollY]);

    if (!isAuthenticated) {
        return (
            <FooterWrapper show={show}>
                <LoginPrompt to="/login">로그인하고 더 보기</LoginPrompt>
            </FooterWrapper>
        );
    }

    const { level, progress, expForNextLevel } = calculateLevelAndProgress(
        user.faith_exp || 0
    );

    return (
        <>
            <ToastContainer
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme={isDarkMode ? "dark" : "light"}
            />
            <FooterWrapper show={show}>
                <ProfileLink to="/profile">
                    <img
                        src={
                            user.user_img
                                ? `${process.env.REACT_APP_PROFILE_IMG_URL}/${user.user_img}`
                                : defaultProfileImage
                        }
                        alt="Profile"
                        onError={(e) => {
                            e.target.src = defaultProfileImage;
                        }}
                    />
                    {user.username || "프로필"}
                </ProfileLink>
                <FaithInfo>
                    <div>신앙도: {level}</div>
                    <div>
                        경험치: {user.faith_exp} / {expForNextLevel}
                    </div>
                </FaithInfo>
                <NotificationLink to="/notifications">
                    <FontAwesomeIcon
                        icon={faBell}
                        shake={unreadNotifications > 0}
                    />
                    {unreadNotifications}
                </NotificationLink>
            </FooterWrapper>
            <ProgressWrapper>
                <ProgressBarContainer>
                    <ProgressBar
                        progress={progress}
                        style={{ background: primaryColor }}
                    />
                </ProgressBarContainer>
            </ProgressWrapper>
        </>
    );
}

export default React.memo(Footer);
