import React, { useState } from "react";
import styled from "styled-components";
import { useTheme } from "../../contexts/ThemeContext";

const ThemeSelectionContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 80px; // Ensure there's space at the bottom for the button
`;

const Title = styled.h2`
  margin-bottom: 20px;
  color: ${({ theme }) => theme.text};
`;

const ThemeOptions = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
`;

const ThemeOption = styled.div`
  width: 200px;
  height: 100px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 2px solid ${(props) => (props.isSelected ? "#4CAF50" : "transparent")};
  box-shadow: ${(props) =>
    props.isSelected ? "0 0 15px rgba(76, 175, 80, 0.5)" : "none"};

  &:hover {
    transform: scale(1.05);
  }
`;

const LightThemeOption = styled(ThemeOption)`
  background-color: #ffffff;
  color: #000000;
`;

const DarkThemeOption = styled(ThemeOption)`
  background-color: #333333;
  color: #ffffff;
`;

const ButtonColorOptions = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 40px;
`;

const ButtonColorOption = styled.div`
  width: 100px;
  height: 50px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 2px solid ${(props) => (props.isSelected ? "#FFD700" : "transparent")};
  box-shadow: ${(props) =>
    props.isSelected ? "0 0 15px rgba(255, 215, 0, 0.5)" : "none"};
  filter: ${(props) => (props.disabled ? "blur(5px)" : "none")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};

  &:hover {
    transform: scale(1.05);
  }
`;

const LightButtonColor1 = styled(ButtonColorOption)`
  background-color: #4474d5;
  color: #ffffff;
`;

const LightButtonColor2 = styled(ButtonColorOption)`
  background-color: #62a24d;
  color: #ffffff;
`;

const DarkButtonColor1 = styled(ButtonColorOption)`
  background-color: #5382e6;
  color: #ffffff;
`;

const DarkButtonColor2 = styled(ButtonColorOption)`
  background-color: #7ac674;
  color: #ffffff;
`;

const NextButton = styled.button`
  width: 85%;
  padding: 15px;
  background-color: ${(props) =>
    props.disabled ? props.theme.toggleBorder : props.theme.buttonBackground};
  color: #ffffff;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: background-color 0.3s;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);

  &:hover {
    background-color: ${(props) =>
      !props.disabled && props.theme.buttonHoverBackground};
  }
`;

const ThemeSelectionStep = ({ updateSignupData }) => {
  const { isDarkMode, toggleTheme, updatePrimaryColor } = useTheme();
  const [selectedButtonColor, setSelectedButtonColor] = useState("");

  const handleSelectTheme = (isDark) => {
    if (isDark !== isDarkMode) {
      toggleTheme();
    }
  };

  const handleSelectButtonColor = (color) => {
    setSelectedButtonColor(color);
    updatePrimaryColor(color);
  };

  const handleNext = () => {
    updateSignupData({
      themeSelected: true,
      primaryColor: selectedButtonColor,
    });
  };

  return (
    <ThemeSelectionContainer>
      <Title>테마를 선택해주세요</Title>
      <ThemeOptions>
        <LightThemeOption
          onClick={() => handleSelectTheme(false)}
          isSelected={!isDarkMode}
        >
          안녕하세요! 👋
        </LightThemeOption>
        <DarkThemeOption
          onClick={() => handleSelectTheme(true)}
          isSelected={isDarkMode}
        >
          안녕하세요! 👋
        </DarkThemeOption>
      </ThemeOptions>
      <ButtonColorOptions>
        <LightButtonColor1
          onClick={() => handleSelectButtonColor("#4474D5")}
          isSelected={selectedButtonColor === "#4474D5"}
          disabled={isDarkMode}
        >
          클릭! 🎨
        </LightButtonColor1>
        <LightButtonColor2
          onClick={() => handleSelectButtonColor("#62A24D")}
          isSelected={selectedButtonColor === "#62A24D"}
          disabled={isDarkMode}
        >
          클릭! 🎨
        </LightButtonColor2>
        <DarkButtonColor1
          onClick={() => handleSelectButtonColor("#5382E6")}
          isSelected={selectedButtonColor === "#5382E6"}
          disabled={!isDarkMode}
        >
          클릭! 🎨
        </DarkButtonColor1>
        <DarkButtonColor2
          onClick={() => handleSelectButtonColor("#7AC674")}
          isSelected={selectedButtonColor === "#7AC674"}
          disabled={!isDarkMode}
        >
          클릭! 🎨
        </DarkButtonColor2>
      </ButtonColorOptions>
      <NextButton onClick={handleNext} disabled={!selectedButtonColor}>
        테마 선택 완료! 🎉
      </NextButton>
    </ThemeSelectionContainer>
  );
};

export default ThemeSelectionStep;