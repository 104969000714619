import React, { useRef, useState, useEffect } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCamera, faUpload } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import axios from "axios";
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";

const ProfileImageModal = ({
    isOpen,
    onRequestClose,
    profile,
    setProfile,
    defaultProfileImage,
}) => {
    const [imageSrc, setImageSrc] = useState(
        profile.user_img || defaultProfileImage
    );
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [croppedArea, setCroppedArea] = useState(null);
    const [zoom, setZoom] = useState(1);
    const [isCameraActive, setIsCameraActive] = useState(false);
    const videoRef = useRef(null);
    const fileInputRef = useRef(null);
    const [stream, setStream] = useState(null);

    useEffect(() => {
        return () => {
            if (stream) {
                stream.getTracks().forEach((track) => track.stop());
            }
        };
    }, [stream]);

    const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
        setCroppedArea(croppedAreaPixels);
    };

    const createImage = (url) =>
        new Promise((resolve, reject) => {
            const image = new Image();
            image.addEventListener("load", () => resolve(image));
            image.addEventListener("error", (error) => reject(error));
            image.setAttribute("crossOrigin", "anonymous");
            image.src = url;
        });

    const getCroppedImg = async (imageSrc, pixelCrop) => {
        const image = await createImage(imageSrc);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const maxSize = Math.max(image.width, image.height);
        const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

        canvas.width = safeArea;
        canvas.height = safeArea;

        ctx.drawImage(
            image,
            safeArea / 2 - image.width * 0.5,
            safeArea / 2 - image.height * 0.5
        );

        const data = ctx.getImageData(0, 0, safeArea, safeArea);

        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;

        ctx.putImageData(
            data,
            Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
            Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
        );

        return new Promise((resolve) => {
            canvas.toBlob((file) => {
                resolve(file);
            }, "image/jpeg");
        });
    };

    const handleImageChange = async () => {
        const token =
            localStorage.getItem("token") || sessionStorage.getItem("token");
        try {
            const croppedImageBlob = await getCroppedImg(imageSrc, croppedArea);
            const formData = new FormData();
            formData.append("profileImage", croppedImageBlob, "profile.jpg");

           await axios.post(
                `${process.env.REACT_APP_API_URL}/api/user/upload-profile-image`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            // Refresh the page
            window.location.reload();

            onRequestClose();
        } catch (error) {
            toast.error("프로필 이미지를 업로드하는 중 오류가 발생했습니다.");
            console.error(error);
        }
    };

    const resetImage = () => {
        setImageSrc(profile.user_img || defaultProfileImage);
        setZoom(1);
        setCrop({ x: 0, y: 0 });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setImageSrc(e.target.result);
                setIsCameraActive(false);
            };
            reader.readAsDataURL(file);
        }
    };

    const startCamera = async () => {
        try {
            const newStream = await navigator.mediaDevices.getUserMedia({
                video: true,
            });
            setStream(newStream);
            setIsCameraActive(true);

            // Wait for the next render cycle before setting srcObject
            setTimeout(() => {
                if (videoRef.current) {
                    videoRef.current.srcObject = newStream;
                }
            }, 0);
        } catch (error) {
            console.error("카메라를 시작하는 중 오류가 발생했습니다:", error);
            toast.error("카메라를 시작할 수 없습니다.");
        }
    };

    const captureImage = () => {
        if (videoRef.current) {
            const canvas = document.createElement("canvas");
            canvas.width = videoRef.current.videoWidth;
            canvas.height = videoRef.current.videoHeight;
            canvas.getContext("2d").drawImage(videoRef.current, 0, 0);
            setImageSrc(canvas.toDataURL("image/jpeg"));
            setIsCameraActive(false);

            // Stop the camera stream
            if (stream) {
                stream.getTracks().forEach((track) => track.stop());
            }
            setStream(null);
        }
    };

    return (
        <StyledModal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            ariaHideApp={false}
        >
            <ModalContent>
                <ModalHeader>
                    <ModalTitle>프로필 이미지 변경</ModalTitle>
                    <CloseButton onClick={onRequestClose}>
                        <FontAwesomeIcon icon={faTimes} />
                    </CloseButton>
                </ModalHeader>
                {isCameraActive ? (
                    <CameraContainer>
                        <Video ref={videoRef} autoPlay playsInline />
                        <CaptureButton onClick={captureImage}>
                            사진 찍기
                        </CaptureButton>
                    </CameraContainer>
                ) : (
                    <CropperContainer>
                        <Cropper
                            image={imageSrc}
                            crop={crop}
                            zoom={zoom}
                            aspect={1}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                        />
                    </CropperContainer>
                )}
                <SliderContainer>
                    <StyledSlider
                        value={zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        aria-labelledby="Zoom"
                        onChange={(e, zoom) => setZoom(zoom)}
                    />
                </SliderContainer>
                <ButtonContainer>
                    <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                        accept="image/*"
                    />
                    <UploadButton onClick={() => fileInputRef.current.click()}>
                        <FontAwesomeIcon icon={faUpload} /> 이미지 선택
                    </UploadButton>
                    <CameraButton onClick={startCamera}>
                        <FontAwesomeIcon icon={faCamera} /> 새로 찍기
                    </CameraButton>
                    <ConfirmButton onClick={handleImageChange}>
                        확정
                    </ConfirmButton>
                    <ResetButton onClick={resetImage}>재설정</ResetButton>
                </ButtonContainer>
            </ModalContent>
        </StyledModal>
    );
};

const StyledModal = styled(Modal)`
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    background-color: ${({ theme }) => theme.background};
    border-radius: 10px;
    padding: 0;
    overflow: hidden;
`;

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 90vw;
    max-width: 500px;
`;
const Button = styled.button`
    background: none;
    color: ${({ theme }) => theme.buttonText};
    border: 2px solid ${({ theme }) => theme.buttonBackground};
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
        background-color: ${({ theme }) => theme.buttonBackground};
        color: ${({ theme }) => theme.buttonHoverText};
    }
`;
const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid ${({ theme }) => theme.borderColor};
`;

const ModalTitle = styled.h2`
    margin: 0;
    color: ${({ theme }) => theme.text};
    font-size: 1.2rem;
`;

const CloseButton = styled(Button)`
    background: none;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    color: ${({ theme }) => theme.text};
`;

const CropperContainer = styled.div`
    position: relative;
    width: 100%;
    height: 300px;
    background-color: ${({ theme }) => theme.background};
`;

const SliderContainer = styled.div`
    width: 85%;
    padding: 20px;
`;

const StyledSlider = styled(Slider)`
    color: ${({ theme }) => theme.primaryColor};
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px;
    flex-wrap: wrap;
`;

const ConfirmButton = styled(Button)`
    color: ${({ theme }) => theme.buttonText};
    border: 1px solid ${({ theme }) => theme.primaryColor};
    background: none;

    &:hover {
        background-color: ${({ theme }) => theme.primaryColor};
        color: ${({ theme }) => theme.buttonHoverText};
    }
`;

const ResetButton = styled(Button)`
    color: ${({ theme }) => theme.buttonText};
    border: 2px solid red;
    background: none;

    &:hover {
        background-color: red;
        color: ${({ theme }) => theme.buttonHoverText};
    }
`;
const UploadButton = styled(Button)`
    background-color: ${({ theme }) => theme.primaryColor};
    color: ${({ theme }) => theme.buttonText};
    &:hover {
        background-color: ${({ theme }) => theme.primaryColorDark};
    }
`;

const CameraButton = styled(Button)`
    background-color: ${({ theme }) => theme.secondaryColor};
    color: ${({ theme }) => theme.buttonText};
    &:hover {
        background-color: ${({ theme }) => theme.secondaryColorDark};
    }
`;

const CameraContainer = styled.div`
    position: relative;
    width: 100%;
    height: 300px;
    background-color: ${({ theme }) => theme.background};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Video = styled.video`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const CaptureButton = styled(Button)`
    position: absolute;
    bottom: 20px;
    background-color: ${({ theme }) => theme.primaryColor};
    color: ${({ theme }) => theme.buttonText};
    &:hover {
        background-color: ${({ theme }) => theme.primaryColorDark};
    }
`;

export default ProfileImageModal;
