import React from 'react';

function Advanced() {
  return (
    <div>
      <h2>심화 페이지</h2>
    </div>
  );
}

export default Advanced;