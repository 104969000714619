import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faTimes } from "@fortawesome/free-solid-svg-icons";

const SheetOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
`;

const SheetContainer = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${({ theme }) => theme.background};
    border-radius: 20px 20px 0 0;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    height: ${({ height }) => height};
    max-height: 90vh;
    overflow: hidden;
    transition: height 0.3s ease;
`;

const SheetContent = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden; /* 시트 내 스크롤 방지 */
`;

const SheetHeader = styled.div`
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid ${({ theme }) => theme.borderColor};
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const SheetTitle = styled.h2`
    margin: 0;
    font-size: 18px;
    color: ${({ theme }) => theme.text};
    flex: 1;
    text-align: center;
`;

const HeaderButton = styled.button`
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: ${({ theme }) => theme.text};
    ${(props) => props.customStyle};
    visibility: ${(props) => (props.hide ? "hidden" : "visible")};
`;

const SheetBody = styled.div`
    flex: 1;
    overflow-y: auto;
    padding: 20px;
`;

const OptionList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;

const OptionItem = styled.li`
    padding: 15px;
    border-bottom: 1px solid ${({ theme }) => theme.borderColor};
    cursor: pointer;
    ${(props) => props.customStyle};
    display: flex;
    align-items: center;

    &:hover {
        background: ${({ theme }) => theme.hoverBackground};
    }

    & > svg {
        margin-right: 10px;
    }
`;

const SwipeIndicator = styled.div`
    width: 50px;
    height: 4px;
    background-color: ${({ theme }) => theme.borderColor};
    border-radius: 2px;
    margin: 10px auto;
`;

const BottomSheet = ({
    isOpen,
    onClose,
    children,
    leftButton = { text: "", onClick: null, style: {} },
    headerText = "옵션",
    rightButton = { text: "Close", onClick: null, style: {} },
    minHeight = "20vh",
    initialHeight = "50vh",
    options = [],
}) => {
    const [height, setHeight] = useState(initialHeight);
    const [isDragging, setIsDragging] = useState(false);
    const [startY, setStartY] = useState(0);
    const [startHeight, setStartHeight] = useState(0);
    const [selectedOption, setSelectedOption] = useState(null);
    const sheetRef = useRef(null);
    const contentRef = useRef(null);

    useEffect(() => {
        if (isOpen) {
            const calculateHeight = () => {
                if (options.length > 0 && !selectedOption) {
                    const optionHeight = Math.min(
                        options.length * 50 + 120, // 헤더와 여백을 고려한 높이
                        window.innerHeight * 0.9
                    );
                    setHeight(`${optionHeight}px`);
                } else if (contentRef.current) {
                    const contentHeight = Math.min(
                        contentRef.current.scrollHeight + 60, // 헤더 높이를 고려
                        window.innerHeight * 0.9
                    );
                    setHeight(`${contentHeight}px`);
                } else {
                    setHeight(initialHeight);
                }
            };

            calculateHeight();
        }
    }, [isOpen, options, selectedOption, initialHeight, children]);

    const handleTouchStart = (e) => {
        setIsDragging(true);
        setStartY(e.touches[0].clientY);
        setStartHeight(sheetRef.current.offsetHeight);
    };

    const handleTouchMove = (e) => {
        if (!isDragging) return;
        const deltaY = startY - e.touches[0].clientY;
        const newHeight = Math.max(
            parseInt(minHeight),
            Math.min(window.innerHeight * 0.9, startHeight + deltaY)
        );
        setHeight(`${newHeight}px`);
    };

    const handleTouchEnd = () => {
        setIsDragging(false);
        const minCloseHeight = Math.max(
            parseInt(minHeight),
            options.length * 50 + 70
        );
        if (sheetRef.current.offsetHeight < minCloseHeight) {
            handleClose();
        }
    };

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
        if (option.onClick) {
            option.onClick();
        }
    };

    const handleClose = () => {
        setSelectedOption(null);
        if (onClose) {
            onClose();
        }
    };

    if (!isOpen) return null;

    return (
        <>
            <SheetOverlay onClick={handleClose} />
            <SheetContainer
                ref={sheetRef}
                height={height}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                <SheetContent>
                    <SwipeIndicator />
                    <SheetHeader>
                        <HeaderButton
                            onClick={
                                leftButton.onClick ||
                                (() => setSelectedOption(null))
                            }
                            customStyle={leftButton.style}
                            hide={!selectedOption}
                        >
                            <FontAwesomeIcon icon={faCog} />
                        </HeaderButton>
                        <SheetTitle>
                            {selectedOption ? selectedOption.title : headerText}
                        </SheetTitle>
                        <HeaderButton
                            onClick={rightButton.onClick || handleClose}
                            customStyle={rightButton.style}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </HeaderButton>
                    </SheetHeader>
                    <SheetBody ref={contentRef}>
                        {selectedOption ? (
                            selectedOption.component
                        ) : (
                            <OptionList>
                                {options.map((option, index) => (
                                    <OptionItem
                                        key={index}
                                        onClick={() =>
                                            handleOptionSelect(option)
                                        }
                                        customStyle={option.style}
                                    >
                                        <FontAwesomeIcon icon={option.icon} />
                                        {option.title}
                                    </OptionItem>
                                ))}
                            </OptionList>
                        )}
                        {children}
                    </SheetBody>
                </SheetContent>
            </SheetContainer>
        </>
    );
};

export default BottomSheet;
